<template>
  <div v-if="sayYes">
    <div class="container">
      <div class="row">
        <div class="col">
          <img
            class="main-gif"
            src="https://media.tenor.com/gUiu1zyxfzYAAAAi/bear-kiss-bear-kisses.gif"
            draggable="false"
          />
          <h1>Soes verga :3</h1>
          <h1>{{ getExtraDetail() }}</h1>
          <button type="button" class="btn btn-primary" @click="reiniciar">
            Otra vez
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <img
            class="main-gif"
            src="https://gifdb.com/images/high/cute-love-bear-roses-ou7zho5oosxnpo6k.gif"
            draggable="false"
          />
          <h1 className="text-4xl my-4">
            ¿{{
              userInfo.value && userInfo.value["name"]
                ? userInfo.value["name"]
                : ""
            }}, serías mi San Valentín?
          </h1>
        </div>
      </div>
      <div class="col">
        <div class="col align-self-end">
          <button
            type="button"
            class="btn btn-success me-3 mb-3"
            :style="{ fontSize: yesFontSize + 'px' }"
            @click="saveSayYes"
          >
            Sí
          </button>
          <button
            type="button"
            class="btn btn-danger mb-3"
            @click="incrementNoCount"
          >
            {{ noCount === 0 ? "No" : getNoButtonText() }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import axios from "axios";
import { ref, reactive } from "vue";

export default {
  name: "SanValentin",
  setup() {
    const route = useRoute();

    const id = ref(route.params.id);
    const attempts = ref(0);
    const noCount = ref(0);
    const sayYes = ref(false);
    const messages = reactive({});
    const userInfo = reactive({});
    const yesFontSize = ref(18);
    const baseUrl = "https://backend-valentine-day.onrender.com";

    return {
      id,
      attempts,
      noCount,
      messages,
      userInfo,
      yesFontSize,
      baseUrl,
      sayYes,
      route,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      await axios
        .get(this.baseUrl + "/get_messages/" + this.id)
        .then((response) => {
          this.messages = response.data["messages"];
          this.userInfo.value = response.data["userInfo"];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    incrementNoCount() {
      if (this.noCount < this.messages.length - 1) {
        this.noCount++;
        this.yesFontSize = this.yesFontSize + 30;
      }
    },
    getNoButtonText() {
      return this.messages[this.noCount].message || "";
    },
    async saveSayYes() {
      const data = {
        fk_person: this.userInfo.value["id"],
        attempts: this.noCount + 1,
      };
      await axios
        .post(this.baseUrl + "/save_attempts", data)
        .then(() => {
          this.sayYes = true;
        })
        .catch(() => {
          this.sayYes = true;
        });
    },
    getExtraDetail() {
      if (this.noCount == 0) {
        return "Ah no mames, no pensé que fuera a la primera hehe";
      } else if (this.noCount > 0 && this.noCount <= 2) {
        return "No pos, lo pensaste, pero soca xD";
      } else if (this.noCount > 2 && this.noCount <= 3) {
        return "...aunque llegado este punto, me lo pienso";
      } else {
        return "....Pucha :'(, como que no soca mejor uwu";
      }
    },
    reiniciar() {
      this.noCount = 0;
      this.sayYes = false;
      this.yesFontSize = 18;
    },
  },
};
</script>
<style scoped>
.main-gif {
  height: 50vh !important;
  width: 80%;
}
</style>
