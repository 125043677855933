import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";
import App from "./App.vue";

import "@/assets/css/bootstrap.min.css";
import "@/assets/js/bootstrap.bundle.min.js";

import HelloWorld from "./components/HelloWorld.vue";
import SanValentin from "./components/SanValentin.vue";

const routes = [
  { path: "/", component: HelloWorld },
  { path: "/person/:id", component: SanValentin },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const app = createApp(App);

app.use(router);

app.mount("#app");
